
import { defineComponent } from "vue";

export default defineComponent({
  name: "ComplementaryFeeding",
  setup() {

    const openTable = () => {
      console.log("openTable()");
      // this.showTable = true;
      // this.setTable();
    }

  },
});
