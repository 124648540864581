<template>
  <section class="bg-light h-full">
    <div class="container py-8">

      <div class="text-primary font-display font-bold text-5xl text-center mb-8">
        Alimentación complementaria
      </div>

      <img class="w-64 mx-auto mb-8" src="/img/complementary-feeding/baby.png" />
      
      <div class="text-primary font-display text-2xl text-center mb-8">Relación entre neurodesarrollo y alimentación</div>

      <img class="md:hidden w-96 mx-auto mb-8" src="/img/complementary-feeding/table.png" />
      <div class="px-12 hidden md:block w-full mb-8">
        <img
          src="/img/complementary-feeding/table-desktop.png"
          srcset="/img/complementary-feeding/table-desktop.png,
                /img/complementary-feeding/table-desktop@2x.png 2x"
        />
      </div>

      <div class="text-primary text-center">Seleccionar el botón debajo para leer o compartir el contenido.</div>

      <div class="text-center">
        <button
          class="mx-auto py-1 px-8 rounded-full uppercase border-2 focus:outline-none
          border-secondary bg-primary text-white font-bold text mt-8 flex flex-row items-center"
          @click="openTable"
        >
          <span>Guía completa</span> <img class="w-4 ml-3" src="/img/share--white.svg" />
        </button>
        <!--
        <div class="">
          <span>Tamaño del archivo:</span>
        </div>
        -->
      </div>

      <!-- 
      <div class="table-holder">
        <div class="table-container">
          <div class="table-header">
            <div class="title" [innerHTML]="'What can babies eat according to their age?' | translate"></div>
            <img src="/img/complementary-feeding/form-header.svg" />
            <ion-button class="btn-close back-button" (click)="hideTable( )">
              <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
            </ion-button>
          </div>
          <div class="table-body">
            <app-complementary-feeding-form [data]="formData"></app-complementary-feeding-form>
            <div class="actions-holder">
              <div class="sharePdf btn btn-blue share" [ngClass]="{'disabled': !isFormValid}" [innerHTML]="'Share' | translate" (click)="sharePDF()"></div>
              <div class="file-info">
                <span [innerHTML]="'File size:' | translate"></span> 707.09 KB
              </div>
            </div>
          </div>
        </div>
      </div>
      -->

    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ComplementaryFeeding",
  setup() {

    const openTable = () => {
      console.log("openTable()");
      // this.showTable = true;
      // this.setTable();
    }

  },
});
</script>
